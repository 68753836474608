import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import Footer from './Footer';
import '../css/tailwind.css';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            tagline
            phone
            phoneFormatted
            email
          }
        }
      }
    `}
    render={data => (
      <div className="antialiased flex flex-col font-sans leading-relaxed min-h-screen text-gray-700 w-full">
        <main className="bg-white flex flex-col flex-1 m-auto w-full">
          {children}
        </main>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
