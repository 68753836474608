import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ siteTitle }) => (
  <footer
    className="bg-black flex flex-wrap font-sans justify-between py-2 text-center text-white uppercase"
    style={{ fontSize: `0.5rem` }}
  >
    <p className="mx-auto md:m-0 pb-2 md:pb-0 px-6">
      Watch this space for content that will get your adrenaline pumping
    </p>
    <p className="mx-auto md:m-0 px-6">
      © {new Date().getFullYear()} {siteTitle} <span className="mx-2">|</span>
      Website by
      <a
        className="inline-flex px-1"
        href="https://phirannodesigns.com.au"
        target="_blank"
        rel="noopener noreferrer"
      >
        Phiranno Designs
      </a>
    </p>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

export default Footer;
